'use client';

import * as yup from 'yup';

import { reactQueryClient } from '@/apiClient';
import { QueryClientProvider } from '@tanstack/react-query';
import { Button, Card, Input } from 'antd';

import { PAGES } from '@/navs';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useForm } from 'react-hook-form';
import { ADMIN_SIGNIN } from './graphql/mutations';
import useToast from './utils/hooks/useToast';

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

const Login = () => {
  const router = useRouter();
  const Toast = useToast();
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [adminSignIn, { loading: adminSignInLoading }] = useMutation<{ adminSignIn: any }, any>(ADMIN_SIGNIN);

  const onSubmit = async (data: Record<string, string>) => {
    const variables = {
      email: data?.email,
      password: data?.password,
    };
    if (!adminSignInLoading) {
      try {
        const res = await adminSignIn({
          variables,
        });
        if (res?.data?.adminSignIn?.token) {
          const token = res?.data?.adminSignIn?.token;
          const message = res?.data?.adminSignIn?.message;
          localStorage.setItem('token', token);
          Toast.success(message || 'Login successful');
          router.replace(PAGES.users);
        }
      } catch (error: any) {
        if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
          Toast.error(error.graphQLErrors[0].message);
        } else {
          Toast.error('Something went wrong');
        }
      }
    }
  };

  return (
    <main className="flex min-h-screen flex-col items-center justify-between p-24">
      <Card>
        <form className="w-80" onSubmit={handleSubmit(onSubmit)}>
          <div className="py-2">
            <label htmlFor="email">Email</label>
            <Input placeholder="Email" id="email" size="large" onChange={(e) => setValue('email', e.target.value, { shouldDirty: true })} />
            {Boolean(errors.email?.message) ? <p className="pt-1 text-red-500">{errors.email?.message}</p> : null}
          </div>

          <div className="py-2">
            <label htmlFor="password">Password</label>
            <Input placeholder="Password" id="password" size="large" type="password" hidden={true} onChange={(e) => setValue('password', e.target.value, { shouldDirty: true })} />
            {Boolean(errors.password?.message) ? <p className="pt-1 text-red-500">{errors.password?.message}</p> : null}
          </div>

          <Button type="primary" size="large" htmlType="submit" style={{ width: '100%' }} className="my-4" loading={adminSignInLoading} disabled={adminSignInLoading}>
            Login
          </Button>

          <Link href={PAGES.forgotPassword}>Forgot password?</Link>
        </form>
      </Card>
    </main>
  );
};

export default function Home() {
  return (
    <QueryClientProvider client={reactQueryClient}>
      <Login />
    </QueryClientProvider>
  );
}
