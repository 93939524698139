import axios, { AxiosResponse } from 'axios';

import qs from 'qs';
import { RequestPayload } from './definitions';

const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL_REST;

console.log({ BASE_URL });

console.log({ BASE_URL });
const baseClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

baseClient.interceptors.request.use(
  async (config) => {
    console.log(`${config.method?.toUpperCase()} ${baseClient.defaults.baseURL}${config.url}  --apiClient.ts`);
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
baseClient.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    console.log('axios error', error.response?.data);
    if (error instanceof axios.AxiosError) {
      console.warn({
        url: error.config?.url,
        code: error.code,
        data: error.response?.data,
      });
    } else {
      console.warn({ error });
    }

    return Promise.reject(error);
  }
);

export type ApiResponseData<T> = Promise<AxiosResponse<T, any>>;

const vendors = {
  checkForUpdate: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/vendors/checkForUpdate', data.body),

  list: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => {
    const query = qs.stringify(data.query);
    console.log({ query, raw: data.query });
    return baseClient.get(`/vendors/list?${query}`);
  },

  getVendors: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/vendors/getVendors'),

  addBranch: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/vendors/addBranch', data.body),

  updateVendor: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> =>
    baseClient.post('/vendors/editPage', data.body, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),

  register: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/vendors/register', data.body),

  getVendorById: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.get('/vendors/getVendorById/', { params: data.query }),

  foodCategoryByVendorId: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.get(`/vendors/${data.params?.vendorId}/foodCategory`),

  getFoods: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/vendors/getFoods'),

  ratings: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/vendors/ratings'),

  orders: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/vendors/orders'),

  notifications: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/vendors/notifications'),

  fetchSingleOrder: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/vendors/fetchSingleOrder'),

  getDispatchedOrders: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/vendors/getDispatchedOrders'),

  getDeliveredOrders: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/vendors/getDeliveredOrders'),

  cancelExtraItem: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/vendors/cancelExtraItem', data.body),

  cancelSingleItem: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/vendors/cancelSingleItem', data.body),

  updateStatus: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/vendors/updateStatus', data.body),

  updateVendorStatusByDispatch: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/vendors/updateVendorStatusByDispatch', data.body),

  getDailyRevenue: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/vendors/getDailyRevenue'),

  acceptStatusByDispatch: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/vendors/acceptStatusByDispatch', data.body),

  deliveryStatusByDispatch: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/vendors/deliveredStatusByDispatch', data.body),

  updateOnlineStatus: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/vendors/updateOnlineStatus', data.body),

  removeRole: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/vendors/removeRole', data.body),

  addRole: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/vendors/addRole', data.body),

  fetchUsers: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/vendors/fetchUsers'),

  dailyRevenue: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/vendors/dailyRevenue'),

  analytics: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/vendors/analytics'),

  getReservations: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/vendors/getReservations'),

  updateReservation: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/vendors/updateReservation', data.body),

  notifyForDelivery: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/vendors/notifyForDelivery'),

  notifyForFailedDelivery: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/vendors/notifyForFailedDelivery'),

  getSpecials: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/vendors/getSpecials'),

  addSpecial: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/vendors/addSpecial', data.body),

  paymentForSpecials: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/vendors/paymentForSpecials', data.body),

  generateCoupon: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/vendors/generateCoupon', data.body),

  updateCoupon: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.delete('/vendors/updateCoupon', data.body),

  getCoupons: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/vendors/getCoupons'),
};

const foods = {
  getFoods: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/foods/getFoods'),

  createAd: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/foods/create-ad', data.body),

  getAds: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/foods/getAds'),

  getFoodById: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.get('/foods/getFoodById', { params: data.query }),

  search: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.get('/foods/search', { params: data.query }),

  hook: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/foods/hook', data.body),

  logView: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/foods/logView'),

  addFood: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/foods/addFood', data.body),

  toggleArchive: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/foods/toggleArchive', data.body),

  editFood: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/foods/editFood', data.body),

  newOrder: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/foods/order', data.body),

  getUserOrderAddresses: <Res, Req extends RequestPayload>(payload: Req): ApiResponseData<Res> => baseClient.get(`/foods/orderAddresses?userId=${payload.query?.userId}`),

  saveWalletDraft: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/foods/saveWalletDraft', data.body),

  saveDraft: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/foods/saveDraft', data.body),

  orders: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/foods/orders'),

  addCustomization: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/foods/addCustomization', data.body),

  deleteCustomization: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/foods/deleteCustomization', data.body),

  customization: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/foods/customization'),

  rateOrder: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/foods/rate', data.body),

  updateOnlineStatus: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/foods/updateOnlineStatus', data.body),

  verifyCoupon: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/foods/verifyCoupon', data.body),

  getCoupons: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.get('/foods/getCoupons', { params: data.query }),

  createOrderManually: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => {
    return baseClient.post('/foods/manual-order', data.body);
  },

  updateOrder: <Res, Req extends RequestPayload>(data: Req) => {
    return baseClient.put(`/foods/orders/${data.params?.orderId}`, data.body);
  },
};

const auth = {
  deleteAccount: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/auth/delete-account', data.body),

  signIn: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/auth/signin', data.body),

  socialLogin: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/auth/socialLogin', data.body),

  signUp: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/auth/signup', data.body),

  recoverPassword: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/auth/recoverPassword', data.body),

  resetConfirmation: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/auth/resetConfirmation', data.body),

  resetPassword: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/auth/resetPassword', data.body),

  refresh: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/auth/refresh'),

  updatePassword: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/auth/updatePassword', data.body),

  clearOneSignalId: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/auth/clearOneSignalId'),

  addAccounting: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/auth/addAccounting', data.body),

  checkForUpdate: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/auth/checkForUpdate', data.body),
};

const users = {
  getProfile: <Res, Req extends RequestPayload>(): ApiResponseData<Res> => baseClient.get('/users/profile'),

  list: <Res, Req extends RequestPayload>(): ApiResponseData<Res> => baseClient.get('/users/list'),

  stripeWebhook: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/users/stripe/webhook', data.body),

  handleCardPayment: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/users/handle-card-payment', data.body),

  deleteCard: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/users/delete-card', data.body),

  switchDefaultCard: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/users/switch-default-card', data.body),

  getCards: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/users/get-cards', data.body),

  saveCard: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/users/save-card', data.body),

  intentsCard: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/users/intents-card', data.body),

  intents: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/users/intents', data.body),

  updateProfile: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/users/updateProfile', data.body),

  checkIfAvailable: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.get('/users/checkIfAvailable', data.body),

  check: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/users/check', data.body),

  checkAddr: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/users/checkAddr', data.body),

  getIntents: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/users/getIntents'),

  addIntent: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/users/addIntent', data.body),

  support: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/users/support', data.body),

  notifications: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/users/notifications'),

  notificationsCount: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/users/notificationsCount'),

  markAsRead: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/users/markAsRead'),

  getWallet: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/users/getWallet'),

  getWalletAmount: <Res, Req extends RequestPayload>(_data: Req): ApiResponseData<Res> => baseClient.get('/users/getWalletAmount'),
};

const admins = {
  editVendorProfile: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => baseClient.post('/admin/editVendorProfile', data.body),

  orders: <Res, Req extends RequestPayload>(data: Req): ApiResponseData<Res> => {
    const query = qs.stringify(data?.query);
    return baseClient.get(`/admin/orders?${query}`);
  },

  resetVendorPassword: <Res, Req extends RequestPayload>(data: Req) => {
    return baseClient.post(`/admin/reset-vendor-password`, data);
  },

  deleteOrders: <Res, Req extends RequestPayload>(data: Req) => {
    console.log(data);
    return baseClient.delete(`/admin/order/${data.params.orderId}`);
  },

  forwardOrderToHandler: <Res, Req extends RequestPayload>(data: Req) => {
    return baseClient.post('/admin/orders/forward', data.body);
  },
};

export const apiClient = {
  vendors,
  foods,
  users,
  admins,
  auth,
};

export const errorMsg = (e): string => {
  let message;
  if (axios.isAxiosError(e)) {
    if (e.response?.data && e.response.data.error) {
      message = e.response?.data?.error;
    } else if (e.response?.data && e.response.data.message) {
      message = e.response?.data?.message;
    } else if (e.response?.status === 500) {
      message = 'An internal error occurred.';
    } else {
      message = e.message;
    }
  } else {
    message = e?.message;
  }

  return message;
};
