export enum PAGES {
  slash = '/',
  forgotPassword = 'forgotPassword',
  home = '/home',
  settings = '/home/settings',
  users = '/home/users',
  vendors = '/home/vendors',
  orders = '/home/orders',
  requestDeliveries = '/home/requests',
  riders = '/home/riders',
}
