import { gql } from '@apollo/client';

export const ADMIN_SIGNIN = gql`
  mutation adminSignIn($email: String!, $password: String!) {
    adminSignIn(email: $email, password: $password) {
      message
      token
    }
  }
`;
